import React, { useState, useEffect } from 'react';
import APILink from '../../service/APILink';
import { stripHtmlTags } from "../../service/funWeb";
import { toast } from 'react-toastify';
import parse from "html-react-parser";
import {setIsCallAPi}  from "../../redux/accction/acction";
import { useSelector, useDispatch } from 'react-redux';
function BuyExcavator() {
  const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    useEffect(() => {
      callAPiFun();
    }, [])
    const callAPiFun = () => {
      APILink.get(`miners`)
        .then((response) => {
          if (response.data.status === "success") {
            setData(response.data.result);
          }
        })
        .catch((error) => {
          console.error(error);
        })
      
    }
    const buyExcavatorFun = (id) => {
    setLoad(true)
      const value ={
        miner_id : id
      }
      APILink.post(`create-miner`,value)
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
          }
          else{
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        }).finally(()=>{
          dispatch(setIsCallAPi(true));
          setLoad(false)
        });
       
    }
  return (
    <div className='row'>
       {data && data.length > 0 && data.map((item, index) => {
          return (
            <div className='col-12 col-lg-6 mt-5 mt-lg-2 row excavator-item flex-class justify-content-center box-buy'>
              <div className='col-4 position-relative box-buy-excavator'>
                <img className='w-100' src={process.env.REACT_APP_SERVER_URL + item.image} />
                <button className='d-none d-lg-block btn-buy btn-buy-custom' onClick={()=>buyExcavatorFun(item.id)} disabled={load}>Mua</button>
              </div>
              <div className='col-8'>
                <h5 className='fs-5'>{item.name}</h5>
                <p className='text-justify mt-4'>{parse(item.description)}</p>
                <div className='mt-3'>
                  <div className='d-block d-lg-flex flex-class justify-content-between mt-2 '>
                    <p>Giá : {item.price}$</p>
                    <p className='mt-2 mt-lg-0 '>Nhận được : {item.earn}$</p>
                    <p className='mt-2 mt-lg-0'>Thời gian : {item.timespan} Ngày</p>
                  </div>
                  <button className='d-block d-lg-none mt-3 btn-buy' onClick={()=>buyExcavatorFun(item.id)}  disabled={load}>Mua</button>
                </div>
              </div>
              <div>

              </div>
            </div>
          )
        })}
    </div>
  )
}

export default BuyExcavator
